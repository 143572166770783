import React from 'react';
import Head from 'next/head';
import {GlobalStyle} from '@hy-vee/web-core/lib/global';
import {hyveeRedOutline} from '@hy-vee/themes';
import {ThemeProvider} from 'styled-components';
import '@hy-vee/design-tokens/src/tokens.css';
import '@hy-vee/design-system/lib/design-system.css';
import '@hy-vee/global-navigation/lib/cjs/index.css';
import {GlobalNavigationProvider} from '@hy-vee/global-navigation';
import {initialize as monitoringInitialize} from '@hy-vee/monitoring';

import {HPlusGlobalStyle} from '../styles/global-style';
import '../../css/index.css';
import '../../css/reset.css';

const clientPaths = {
    apiPrefix: '/plus/api',
    loginPath: '/plus/login',
    logoutPath: '/plus/logout',
};

const MyApp = (props) => {
    const {Component, ...pageProps} = props;

    React.useEffect(() => {
        monitoringInitialize();
    }, []);

    return (
        <>
            <Head>
                <title>{'Hy-Vee PERKS Plus Premium Membership | Hy-Vee'}</title>
            </Head>
            <GlobalStyle />
            <HPlusGlobalStyle />
            <ThemeProvider theme={hyveeRedOutline}>
                <GlobalNavigationProvider clientPaths={clientPaths}>
                    <Component {...pageProps} />
                </GlobalNavigationProvider>
            </ThemeProvider>
        </>
    );
};

export default MyApp;
